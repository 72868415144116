

















































































import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataListReminderContract,
  ResponseGetListReminderContract,
} from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  mixins: [MNotificationVue],
})
export default class ReminderContract extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reminderContractList: ResponseGetListReminderContract = {
    data: [],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  };
  loadingIndicator = {
    data: true,
    loadMore: false,
  };
  disableLoadingMoreBtn = false;
  query: RequestQueryParamsModel = {
    page: 0,
    search: "",
  };
  dataTitle = ["IC Number", "BAPU Number", "Unit Received Date", "Approved"];
  columnsTable = [
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
      key: "icNumber",
      scopedSlots: { customRender: "icNumber" },
    },
    {
      title: this.$t("lbl_bapu_number"),
      dataIndex: "bapuNumber",
      key: "bapuNumber",
      scopedSlots: { customRender: "bapuNumber" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCodeParent",
      key: "unitCodeParent",
      scopedSlots: { customRender: "unitCodeParent" },
    },
    {
      title: this.$t("lbl_unit_receipt_date"),
      dataIndex: "unitReceivedDate",
      key: "unitReceivedDate",
      scopedSlots: { customRender: "unitReceivedDate" },
    },
    {
      title: this.$t("lbl_approval"),
      dataIndex: "approved",
      key: "approved",
      scopedSlots: { customRender: "approved" },
    },
  ];

  created(): void {
    this.getReminderContract().then(res => {
      this.reminderContractList = res;
    });
  }

  async handleApprove(item) {
    try {
      if (!item.receiveDate) {
        this.showNotifError("lbl_receive_date_mandatory");
      } else {
        await salesOrderServices.approveReminderContract(item.id, {
          receiveDate: item.receiveDate,
        });
        this.showNotifSuccess("notif_create_success");
        this.query.page = 0;
        this.getReminderContract().then(res => {
          this.reminderContractList = res;
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getReminderContract(): Promise<ResponseGetListReminderContract> {
    try {
      this.loadingIndicator.data = true;
      const param: RequestQueryParamsModel = {
        limit: 8,
        page: this.query.page,
      };
      if (this.query.search) {
        param.search = `${this.query.search}`;
      }
      const res = await salesOrderServices.getListReminderContract(param);
      res.data.forEach((d, index) => {
        d.key = index;
        d.rentPeriod = `${moment(d.startRent).format(
          DEFAULT_DATE_FORMAT
        )} - ${moment(d.endRent).format(DEFAULT_DATE_FORMAT)}`;
        const contractDate = d.rentPeriod.split(" - ");
        if (
          moment(contractDate[1], DEFAULT_DATE_FORMAT).isBefore(moment(), "day")
        ) {
          d.alreadyDue = true;
        } else {
          d.alreadyDue = false;
        }
      });
      if (
        res.totalPages === (this.query.page as number) + 1 ||
        res.totalPages === 0
      ) {
        this.disableLoadingMoreBtn = true;
      }
      this.loadingIndicator.data = false;
      this.loadingIndicator.loadMore = false;
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  onLoadMore(): void {
    this.loadingIndicator.loadMore = true;
    this.query.page = ++(this.query.page as number);
    this.getReminderContract().then(res => {
      this.reminderContractList.data = this.reminderContractList.data.concat(
        res.data
      );
    });
  }

  async navigateToDetail(data: DataListReminderContract) {
    try {
      const params: RequestQueryParamsModel = {
        search: `documentNo~*${data.icNumber}*`,
      };
      const responseIc = await salesOrderServices.getListInternalContract(
        params
      );
      if (responseIc.data.length > 0) {
        this.$confirm({
          title: this.$t("lbl_validation_redirect"),
          onOk: () => {
            this.$router.push(
              `/sales/internal-contract/view/${responseIc.data[0].id}`
            );
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.showNotifError("notif_data_not_found");
      }
    } catch (err) {
      this.showNotifError("lbl_error");
    }
  }

  onSearch(value: string): void {
    this.query.page = 0;
    this.query.search = value;
    this.getReminderContract().then(res => {
      this.reminderContractList.data = res.data;
    });
  }
}
