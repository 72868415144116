var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_reminder_contract") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-input-search", {
                attrs: {
                  placeholder: _vm.$t("lbl_input_ic_placeholder"),
                  loading: _vm.loadingIndicator.data
                },
                on: { search: _vm.onSearch }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  columns: _vm.columnsTable,
                  "data-source": _vm.reminderContractList.data,
                  scroll: { y: 520 },
                  pagination: false,
                  loading: _vm.loadingIndicator.data
                },
                scopedSlots: _vm._u([
                  {
                    key: "icNumber",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            class: { "text-danger": record.alreadyDue },
                            on: {
                              click: function($event) {
                                return _vm.navigateToDetail(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(record.icNumber) + " ")]
                        ),
                        _c(
                          "p",
                          {
                            class: { "text-danger": record.alreadyDue },
                            staticStyle: { margin: "0" }
                          },
                          [_vm._v(" " + _vm._s(record.rentPeriod) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "bapuNumber",
                    fn: function(text, record) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(record.bapuNumber || "-") + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "unitCodeParent",
                    fn: function(text, record) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(record.unitCodeParent || "-") +
                              " " +
                              _vm._s(
                                record.unitCodeChildList &&
                                  record.unitCodeChildList.length > 0
                                  ? "; " + record.unitCodeChildList.join(",")
                                  : null
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "unitReceivedDate",
                    fn: function(text, record) {
                      return [
                        record.bapuNumber
                          ? _c("a-date-picker", {
                              attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                              model: {
                                value: record.receiveDate,
                                callback: function($$v) {
                                  _vm.$set(record, "receiveDate", $$v)
                                },
                                expression: "record.receiveDate"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "approved",
                    fn: function(text, record) {
                      return [
                        record.bapuNumber
                          ? _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleApprove(record)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_approve")) + " "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    disabled: _vm.disableLoadingMoreBtn,
                    block: "",
                    loading: _vm.loadingIndicator.data
                  },
                  on: { click: _vm.onLoadMore }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.loadingIndicator.data
                          ? _vm.$t("lbl_loading")
                          : _vm.$t("lbl_load_more")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }